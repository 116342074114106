import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { map, switchMap } from 'rxjs';

import { HeaderService } from '@/services/header.service';
import { ConfigService } from '@/services/config.service';
import { AuthService } from '@/services/auth.service';

export function authGuard(): CanActivateFn {
  return () => {
    const configService = inject(ConfigService);
    const headerService = inject(HeaderService);
    const authService = inject(AuthService);
    const router = inject(Router);

    return configService.config$.pipe(
      switchMap(() => authService.connectedUser$),
      map((connectedUser) => {
        if (!connectedUser) {
          if (configService.isBrowser) {
            headerService.showLoginModal(true, router.getCurrentNavigation()?.extractedUrl);
          }
          return false;
        }
        return true;
      })
    );
  };
}
