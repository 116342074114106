import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { map, switchMap } from 'rxjs';

import { ConfigService } from '@/services/config.service';
import { AuthService } from '@/services/auth.service';
import { MainRoutes } from '@/constants';

export function favorisGuard(): CanActivateFn {
  return () => {
    const configService = inject(ConfigService);
    const authService = inject(AuthService);
    const router = inject(Router);

    return configService.config$.pipe(
      switchMap(() => authService.connectedUser$),
      map((connectedUser) => {
        if (!connectedUser) {
          if (configService.isBrowser) {
            return router.createUrlTree([MainRoutes.Favoris + '/login']);
          }
          return false;
        }
        return true;
      })
    );
  };
}
